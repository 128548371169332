<template>
  <div class="culti-container container">
    <div class="container-fluid clear">
      <div v-if="notices.length > 0" class="fl culti-box">
        <div class="culti-box-notice clear">
          <div class="fl title">培训通知</div>
          <div class="fr" style="cursor: pointer" @click="trainMore('1')">
            <span class="culti-box-notice-more">更多</span>
            <!-- <span class="icon"></span> -->
          </div>
        </div>
        <div
          class="clear culti-list"
          v-for="(itme, index) in notices.slice(0, 5)"
          :key="index"
          @click="train(itme)"
        >
          <div class="fl culti-list-number">{{ index + 1 }}</div>
          <div class="fl culti-list-title">{{ itme.subject }}</div>
          <div class="fr culti-list-time">
            {{ itme.createDate | timeFormatter("{year}年{month}月{day}日") }}
          </div>
        </div>
      </div>
      <div v-if="dynamic.length > 0" class="fl culti-box">
        <div class="culti-box-notice clear">
          <div class="fl title">培训动态</div>
          <div class="fr" style="cursor: pointer" @click="trainMore('2')">
            <span class="culti-box-notice-more">更多</span>
            <!-- <span class="icon"></span> -->
          </div>
        </div>
        <div
          class="clear culti-list"
          v-for="(itme, index) in dynamic.slice(0, 5)"
          :key="index"
          @click="train(itme)"
        >
          <div class="fl culti-list-number">{{ index + 1 }}</div>
          <div class="fl culti-list-title">{{ itme.subject }}</div>
          <div class="fr culti-list-time">
            {{ itme.createDate | timeFormatter("{year}年{month}月{day}日") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { timeFormatter, routerPush } from '@/utils/verify';
export default {
  name: 'CultiVate',
  filters: { timeFormatter },
  props: {
    dynamic: {
      type: Array,
      default: []
    },
    notices: {
      type: Array,
      default: []
    }
  },
  methods: {
    trainMore(e) {
      routerPush(this, '/trainMore', { type: e });
    },
    train(e) {
      routerPush(this, '/train', { id: e.id });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.culti {
  &-container {
    box-sizing: border-box;
    padding: 40px 0;
    overflow: hidden;
  }
  &-box {
    width: 50%;
    box-sizing: border-box;
    padding-right: 30px;
    &:last-of-type{
      padding-right: 0;
      padding-left: 30px;
    }
    .title {
      .fontSize(@TEXT-SIZE-24);
      font-weight: 400;
      color: @TEXT-COLOR-363636;
    }
    &-notice {
      color: @TEXT-COLOR-888888;
      position: relative;
      margin-bottom: 21px;
      font-weight: 400;
      .icon {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: url("~@/assets/images/home/you-jian-to.png") no-repeat 0px
          0px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -8px;
      }
      &-more {
        line-height: 24px;
        .fontSize(@TEXT-SIZE-14);
      }
    }
  }

  &-list {
    line-height: 40px;
    overflow: hidden;
    box-sizing: border-box;
    height: 40px;
    cursor: pointer;
    .fontSize(@TEXT-SIZE-14);
    position: relative;
    &:hover &-title {
      color: @--color-primary;
    }
    &-number {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      text-align: center;
      overflow: hidden;
      background: @--color-primary;
      color: @TEXT-COLOR-FFFFFF;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -11px;
      line-height: 22px;
    }
    &-title {
      .fontSize(@TEXT-SIZE-16);
      margin-left: 42px;
      overflow: hidden;
      max-width: 375px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-time {
      color: @TEXT-COLOR-888888;
    }
  }
}
</style>