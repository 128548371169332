<template>
  <div class="home">
    <div class="container home-carousel-item">
      <el-carousel indicator-position="outside" :interval="3000">
        <el-carousel-item v-for="(item, index) in carouselsArr" :key="index">
          <div
            class="el-carousel-item-bg container"
            :style="{ backgroundImage: 'url(' + item.webImgUrl + ')' }"
          >
            <div class="container">
              <div class="container-fluid">
                <h1 class="el-title" @click="carouselSkip(item)"></h1>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="h-personal-information">
        <div class="container">
          <div class="container-fluid" style="position: relative">
            <div
              class="h-personal-wx-login h-login-wx-login"
              v-if="!consumType"
            >
              <WxLogin></WxLogin>
            </div>
            <div v-if="consumType" class="h-personal-wx-login">
              <LoginSuccess></LoginSuccess>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CultiVate :dynamic="dynamic" :notices="notices"></CultiVate>
    <div class="container">
      <div class="container-fluid">
        <h1 class="title">课程推荐</h1>
      </div>
    </div>
    <CourseRecom :liveBoradRecordedArr="liveBoradRecordedArr"></CourseRecom>
    <Fixed :top="scrollTop"></Fixed>
  </div>
</template>
<script>
import Vue from 'vue';
import { carousel, carouselItem } from 'element-ui';
Vue.use(carousel);
Vue.use(carouselItem);
import { mapGetters } from 'vuex';
import LoginSuccess from './components/login-success';
import CultiVate from './components/culti-vate';
import CourseRecom from '@/components/courseRecom/index';
import Fixed from './components/fixed';
import WxLogin from '@/components/wxLogin/index';
import { routerPush } from '@/utils/verify';
import { miniHomePageActivitys } from '@/api/api';
export default {
  name: 'Home',
  components: {
    LoginSuccess,
    CultiVate,
    CourseRecom,
    Fixed,
    WxLogin
  },
  data() {
    return {
      scrollTop: 527,
      liveBoradRecordedArr: [], // 课程数组
      carouselsArr: [], // 轮播广告
      dynamic: [], // 资讯动态
      notices: [] // 资讯通知
    };
  },
  computed: {
    ...mapGetters(['consumType'])
  },
  created() {
    this.getMiniHomePageActivitys();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const _this = this;
      if (localStorage.getItem('H5_LOGIN_TYPE')) {
        _this.$store.commit('setConsumType', true);
      } else {
        _this.$store.commit('setConsumType', '');
      }
      window.addEventListener('scroll', _this.handleScroll, true);
    },
    handleScroll() {
      const _this = this;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop < 527) {
        _this.scrollTop = 527 - parseInt(scrollTop);
      } else {
        _this.scrollTop = 10;
      }
    },
    /**
     * 首页接口信息返回
     * @liveBoradcastActivitys  公开课->直播
     * @recordedActivitys       精品课->录播
     * @carousels               轮播图
     * @dynamic                 资讯动态
     * @notices                 资讯通知
     * */
    getMiniHomePageActivitys() {
      const _this = this;
      miniHomePageActivitys({}).then((res) => {
        if (res.code == 200) {
          const {
            liveBoradcastActivitys,
            recordedActivitys,
            carousels,
            dynamic,
            notices,
            onsiteTeaching
          } = res.data;
          const arr = liveBoradcastActivitys
            .concat(recordedActivitys)
            .concat(onsiteTeaching);
          // 排序
          const compare = (obj1, obj2) => {
            const val1 = obj1.priority;
            const val2 = obj2.priority;
            const valTime1 = obj1.activityPublishTime;
            const novTime = obj2.activityPublishTime;
            if (val1 == val2 && valTime1 > novTime) {
              return -1;
            } else if (val1 == val2 && valTime1 < novTime) {
              return 1;
            } else if (val1 != val2 && val1 > val2) {
              return -1;
            } else if (val1 != val2 && val1 < val2) {
              return 1;
            }
            return 0;
          };
          _this.liveBoradRecordedArr = arr.sort(compare).slice(0, 8);
          _this.carouselsArr = carousels;
          _this.dynamic = dynamic;
          _this.notices = notices;
        }
      });
    },
    carouselSkip(itme) {
      const _this = this;
      if (itme.type == 'ACTIVITY') {
        routerPush(_this, '/courseDetails', { id: itme.redirectId });
      } else {
        routerPush(_this, '/train', { id: itme.redirectId });
      }
    }
  },
  // 离开单前页面执行
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.home {
  box-sizing: border-box;
  .home-carousel-item {
    height: 400px;
    overflow: hidden;
    position: relative;
    .el-carousel-item-bg {
      width: 100%;
      height: 400px;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      background-size: cover;
      .el-title {
        margin-left: 83px;
        font-size: 50px;
        color: #ffffff;
        font-weight: bold;
        box-sizing: border-box;
        padding-top: 400px;
        width: 50%;
        overflow: hidden;
        cursor: pointer;
      }
    }
    /deep/.el-carousel__indicators--outside {
      position: absolute;
      bottom: 10px;
      margin-left: -45px;
      z-index: 100;
      /deep/.el-carousel__indicator--horizontal {
        padding: 0;
        margin-right: 10px;
        background: @--color-primary;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        overflow: hidden;
      }
      /deep/.is-active {
        background-color: @TEXT-BG-FFFFFF;
        width: 40px;
        border-radius: 10px;
        transition: all 0.3s linear;
      }
      /deep/.el-carousel__button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transition: all 0.3s linear;
      }
    }
    /deep/.el-carousel__indicators--outside button {
      background-color: transparent;
    }
    /deep/.el-carousel__container {
      height: 400px;
    }
    .h-personal-information {
      position: absolute;
      top: 0;
      left: 0;
      height: 400px;
      overflow: hidden;
      width: 100%;
    }
  }
  .h-personal-wx-login {
    width: 320px;
    height: 360px;
    background: @TEXT-BG-FFFFFF;
    border-radius: 5px;
    position: absolute;
    right: 0;
    top: 20px;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 10;
  }
  .h-login-wx-login {
    padding: 30px 20px 0 20px;
  }
  .title {
    height: 31px;
    font-weight: 400;
    line-height: 31px;
    .fontSize(@TEXT-SIZE-24);
    margin-bottom: 30px;
    font-weight: 400;
  }
}
</style>