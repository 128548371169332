<template>
  <div class="course-container container">
    <div class="container-fluid">
      <div class="clear course-box">
        <div
          class="course-list fl"
          v-for="(itme, index) in liveBoradRecordedArr"
          :key="index"
          @click="courseRecommendedIncident(itme, index)"
        >
          <div class="course-list-video">
            <img :src="itme.imgUrl" alt="" />
            <template v-if="itme.tag === 'LIVE_BROADCAST'">
              <div class="course-list-live">
                <div>{{ itme.liveTag | live_tag }}</div>
              </div>
            </template>
          </div>
          <div class="course-list-msg">
            <div class="title">{{ itme.activityName }}</div>
            <p>{{ itme.tag | course_classification }}</p>
            <div class="clear btm">
              <div class="fl num">{{ itme.holdUpSeats || 0 }}人已报名</div>
              <template v-if="itme.studyHour > 0">
                <div class="fr" style="fontsize: 14px">学时</div>
                <div class="fr">
                  <span>{{ itme.studyHour }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { live_tag, course_classification } from '@/config/statusCode';
import { routerPush } from '@/utils/verify';
export default {
  name: 'CourseRecom',
  filters: {
    live_tag,
    course_classification
  },
  props: {
    liveBoradRecordedArr: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    courseRecommendedIncident(itme, index) {
      routerPush(this, '/courseDetails', { id: itme.id });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.course {
  &-container {
    padding: 0 0 40px 0;
    box-sizing: border-box;
  }
  &-box {
    box-sizing: border-box;
    width: 100%;
  }
  &-list {
    width: 280px;
    height: 300px;
    margin-right: 20px;
    background: @TEXT-BG-FFFFFF;
    border: 1px solid @TEXT-BG-E6E6E6;
    cursor: pointer;
    margin-bottom: 30px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:hover &-video img {
      transform: scale(1.05);
    }
    &:hover &-msg .title {
      color: @--color-primary;
      transition: all 0.2s linear;
    }
    &:hover {
      transition: all 0.15s linear;
      box-shadow: 0 0 30px rgba(27, 31, 46, 0.09);
    }
    &-video {
      width: 100%;
      height: 158px;
      position: relative;
      overflow: hidden;
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        transition: all 0.3s linear;
      }
    }
    &-live {
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      background: @--color-primary;
      color: @TEXT-COLOR-FFFFFF;
      .fontSize(@TEXT-SIZE-12);
      text-align: center;
      line-height: 28px;
    }

    &-msg {
      padding: 15px 15px 0 15px;
      box-sizing: border-box;
      width: 100%;
      height: 142px;
      position: relative;
      .title {
        .fontSize(@TEXT-SIZE-16);
        line-height: 24px;
        margin-bottom: 10px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: all 0.6s linear;
      }
      p {
        .fontSize(@TEXT-SIZE-14);
        color: @TEXT-COLOR-888888;
      }
      .btm {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0 15px 10px 15px;
        line-height: 32px;
        color: @TEXT-COLOR-888888;
        .fontSize(@TEXT-SIZE-12);
        span {
          color: @TEXT-COLOR-FF5102;
          .fontSize(@TEXT-SIZE-24);
          margin-right: 5px;
        }
      }
    }
  }
}
</style>