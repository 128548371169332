<template>
  <div class="fixed-container" :style="{ top: top + 'px' }">
    <div class="flex fixed-box">
      <div
        :class="['fixed-box-top', active ? 'active' : '']"
        style="cursor: pointer"
        @mouseover="
          () => {
            active = true;
          }
        "
        @mouseleave="
          () => {
            active = false;
          }
        "
      ></div>
    </div>
    <div :class="['fixed-box-bom', 'con', active ? 'active' : '']">
      <div class="con conet fixed-box-bom-bor">
        <div class="bg"></div>
        <p>南方研学基地</p>
        <p>小程序</p>
      </div>
      <div class="con conet fixed-box-bom-bor">
        <img src="~@/assets/images/home/QR-code.jpg" alt="" />
        <p>南方研学基地</p>
        <p>公众号</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Fixed',
  props: {
    top: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      active: false
    };
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.fixed {
  &-container {
    position: fixed;
    right: 10px;
  }
  &-box {
    justify-content: flex-end;
    &-top {
      background: url("~@/assets/images/home/ewm117.png") no-repeat 0px 0px;
      width: 60px;
      height: 60px;
      // &.active {
      //   animation: reverseRotataZ 4s linear infinite;
      // }
    }
    &-bom {
      width: 100%;
      box-sizing: border-box;
      transform: translateX(200px);
      transition: all 0.3s linear;
      &.active {
        transform: translateX(0px);
        transition: all 0.3s linear;
      }
      &-bor {
        border: 1px solid #e6e6e6;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        margin-top: 13px;
        background: #fff;
        border-radius: 5px;
        img {
          width: 120px;
          height: 120px;
          background: url("~@/assets/images/home/QR-code.jpg") no-repeat 0px 0px;
        }
        .bg {
          width: 120px;
          height: 120px;
          background: url("~@/assets/images/home/ewm.jpg") no-repeat 0px 0px;
          background-size: 120px 120px;
        }
        p {
          .fontSize(@TEXT-SIZE-12);
          color: @TEXT-COLOR-888888;
          line-height: 20px;
        }
      }
    }
  }
}
@keyframes reverseRotataZ {
  0% {
    -webkit-transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
  }
}
</style>
